import { Link, Stack } from "@mui/material"

const SOCIAL_MEDIA_LINKS = [
    {
        social: "youtube",
        link: "https://www.youtube.com/@SkizaaAI",
        iconpath: "/logo/social/yt.svg"
    },
    {
        social: "twitter(X)",
        link: "https://x.com/skizaaAI",
        iconpath: "/logo/social/x_new.svg"
    },
    {
        social: "linkedin",
        link: "https://www.linkedin.com/company/skizaa",
        iconpath: "/logo/social/linkedin.svg"
    },
]

export const SocialMediaStack = ()=> {
    return(
        <Stack
            flexDirection={"row"}
            alignItems={"center"}
            gap={"1.5rem"}
        >
            { SOCIAL_MEDIA_LINKS.map((item, index)=> (
                <Link href={item.link} key={index} target="_blank">
                    <img src={item.iconpath} alt={item.social} width={24} height={24} />
                </Link>
            ))}
        </Stack>
    )
}