import { Stack, styled, Typography } from "@mui/material";

export const HeroSectionArea = styled(Stack)(({ theme }) => ({
    width: "62%",
    margin: "4rem auto",
    [theme.breakpoints.down('sm')]:{
        width: "100%",
        margin: "2.5rem auto 0 auto"
    }
}))

export const HeroTextTitle = styled(Typography)(({ theme }) => ({
    fontFamily: "Neue Haas Grotesk Display Pro",
    fontSize: "4rem",
    fontWeight: 700,
    lineHeight: "150%",
    color: "#212B36",
    [theme.breakpoints.down("xl")]:{
        fontSize: "3rem",
    },
    [theme.breakpoints.down("lg")]:{
        fontSize: "2rem",
    },
    [theme.breakpoints.down("md")]:{
        fontSize: "1.5rem",
    },
    [theme.breakpoints.down('sm')]:{
        fontSize: "1.5rem"
    }
}))

export const HeroTextDesc = styled(Typography)(({ theme }) => ({
    fontSize: "1.25rem",
    fontWeight: 300,
    color: "#212B36",
    [theme.breakpoints.down("lg")]:{
        fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]:{
        fontSize: "0.8rem",
    },
    [theme.breakpoints.down('sm')]:{
        fontSize: "0.75rem"
    }

}))