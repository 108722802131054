import { Grid, Stack, styled } from "@mui/material"

const SPONSER_ICONS = [
    {
        id: 1,
        name: "food4Education",
        logo: "/logo/sponsers/food4education.png"
    },
    {
        id: 2,
        name: "uncover",
        logo: "/logo/sponsers/uncover.png"
    },
    {
        id: 3,
        name: "payd",
        logo: "/logo/sponsers/payd.png"
    },
    {
        id: 4,
        name: "open_campus",
        logo: "/logo/sponsers/opencampus.png"
    },
    {
        id: 5,
        name: "teach_for_kenya",
        logo: "/logo/sponsers/teach_for_kenya.png"
    },
    {
        id: 6,
        name: "zeraki",
        logo: "/logo/sponsers/zeraki.png"
    },
    {
        id: 7,
        name: "buupass",
        logo: "/logo/sponsers/buupass.png"
    },
    {
        id: 8,
        name: "smart_reja",
        logo: "/logo/sponsers/smart_reja.png"
    },
    {
        id: 9,
        name: "evChaja",
        logo: "/logo/sponsers/evchaja.png"
    },
    {
        id: 10,
        name: "kafrehs",
        logo: "/logo/sponsers/kafresh.png"
    }
]

export const SponserLogoStack = ()=> {
    return(
        <GridContainer container spacing={2}>
            { SPONSER_ICONS.map((item, index)=> (
                <GridItem item xs={2} sm={2} lg={1} xl={1} key={ item.id } >
                    <ImageCard src={item.logo} alt={item.name}/>
                </GridItem>
            ))}
        </GridContainer>
    )
}

const GridContainer = styled(Grid)(({ theme }) => ({
    margin: "1rem auto 2.5rem auto"
}))
const GridItem = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "1.5rem",
    margin: "0.5rem auto",
    padding: "0 3.5rem",
    [ theme.breakpoints.down('md')]:{
        margin: "0.4rem auto",
    }   
}))

const ImageCard = styled('img')(({ theme }) => ({

    [ theme.breakpoints.down('md')]:{
        width: "3rem",
        height: "auto",
    }  
}))