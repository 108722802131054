import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import styled from "@emotion/styled";
import { Link } from 'react-router-dom';

const Container = styled(Grid)({   
  margin: "10px 100px 25px 100px",
  "@media (max-width: 960px)": {
    margin: "5px 5px 5px 5px",
  },
});

const InnerBox = styled(Box)({
  // background: "#EDEFFF",
  padding: "48px 86px",
  textAlign:'left',
  "@media (max-width: 960px)": {
    width: "98%",
    marginLeft: "1%",
    // padding: "20px 15px 70px 15px",
  },
});
const Heading1 = styled(Typography)({
  fontSize: "28px",
  fontWeight: "600",
  lineHeight: "48px",
  margin: "2rem 0 2rem 0",
  fontFamily: "Poppins",
  color: "#353535",
  display:'flex',
  justifyContent:'center',
});

const SubtitleText = styled.h3`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #353535;
  `;
  
  const Paragraph = styled(Typography)({
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "24px",
    fontFamily: "Poppins",
    color: "#676767",
    flex: "none",
    flexGrow: "0",
    });

const ContactLink = styled.a`
  fontSize: "16px",
  fontWeight: "400",
  fontStyle: "normal",
  lineHeight: "24px",
  fontFamily: "Poppins",
  color: "#676767",
  flex: "none",
  order: "1",
  flexGrow: "0",
`;

const Icon = styled("img")({
  width: "100px",
  height: "100px",
  marginRight:'1rem'
})


export default function ConnectPrivacy_policy() {
  return (
    <Container>
      <InnerBox>
        <div style={{display:'flex',justifyContent:'center',marginBottom:'1rem'}}>
            <Icon
                src={"/skizaa/Brandmark.png"}
            />
         </div>
          <Heading1>PRIVACY POLICY SKIZAA EDUCATION LIMITED</Heading1>
          <SubtitleText>Privacy Policy</SubtitleText>
          <Paragraph>
            Our platform offers an enhanced feature allowing integration with Google Spreadsheets via Google OAuth, enabling seamless export of data from our survey forms to your designated spreadsheet. To provide this functionality, we request and utilize access and refresh tokens, generated uniquely for each user, to facilitate secure communication between our platform and your Google Spreadsheet.
          </Paragraph>

          <SubtitleText>Accessing and Using Information</SubtitleText>
          <Paragraph>
            When you grant access to your spreadsheet, we generate access and refresh tokens. These tokens are used exclusively to enable the integration between our survey forms and your Google Spreadsheet. They are stored securely within our highly protected AWS database server. The access token enables our platform to read and write data to your spreadsheet, allowing you to export survey data for analysis and decision-making. The refresh token is used to maintain long-term access and ensure uninterrupted functionality.
          </Paragraph>

          <SubtitleText>Storage of Information</SubtitleText>
          <Paragraph>
          Your access and refresh tokens are stored securely in our AWS database server, employing advanced security measures to safeguard this information. We prioritize the protection of your data and ensure that it is inaccessible to unauthorized parties.
          </Paragraph>

          <SubtitleText>Non-Disclosure of Information</SubtitleText>
          <Paragraph>
            We value your privacy and adhere to strict confidentiality standards. Your access and refresh tokens, as well as any data related to the Google Spreadsheet integration, are not shared or disclosed to any third parties. Your information remains solely within our platform and is used solely for the purpose of enabling the integration as specified.
          </Paragraph>

          <SubtitleText>Limited Use Compliance</SubtitleText>
          <Paragraph>
            We are committed to complying with Google's Limited Use Policy. To learn more about how we handle your data, please review our <Link to='https://developers.google.com/terms/api-services-user-data-policy' target="_blank">Limited Use Compliance Disclosure</Link>.
          </Paragraph>

          <SubtitleText>Acceptance of Privacy Policy</SubtitleText>
          <Paragraph>
            By choosing to integrate your data with the Google Spreadsheet, you indicate your acknowledgment and acceptance of this privacy policy in its entirety. If you disagree with any aspect of this policy, you retain the right to opt out and choose not to integrate your data with the spreadsheet.
            We are committed to ensuring the security and privacy of your information throughout the integration process. If you have any concerns or queries regarding the handling of your data, please don't hesitate to contact us at
            {" "}<ContactLink href="mailto:contact@skizaa.com" > contact@skizaa.com </ContactLink>.
          </Paragraph>
      </InnerBox>
    </Container>
  );
}
