import React from "react";
import SkizaaIcon from "../../Templates/SkizaaIcon";
import { SocialMediaStack } from "./MainFooter.constants";
import { FooterContainer,
    HorizontalStack,
    VerticalStack,
    LegalStack,
    StyledTypography,
    InfoStack,
    StyledLink} from "./MainFooter.styles"

const MainFooter = ()=> {
    return(
        <FooterContainer>
            <HorizontalStack>
                <VerticalStack>
                    <InfoStack>
                        <SkizaaIcon width={102} height={24} />
                        <StyledTypography>© 2024 Skizaa. All Rights Reserved.</StyledTypography>
                    </InfoStack>
                    <LegalStack>
                        <StyledLink href="/terms-of-use" target="_blank">Terms of Service</StyledLink>
                        <StyledLink href="/privacy-policy" target="_blank">Privacy Policy</StyledLink>
                    </LegalStack>
                </VerticalStack>
                <SocialMediaStack/>
            </HorizontalStack>
        </FooterContainer>
    )
}

export default MainFooter