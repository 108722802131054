export const workbookData = {
    workbookId: "9a391513-a8fa-4d69-9e55-4e4668c236b7",
    columns: {
        name: "1770a881-341e-4541-9288-a6e7af0745c3",
        email: "5ad54f08-e8b6-4d2e-879c-5f7403bf13e5",
        role: "0d7aa9e1-6978-4994-ac70-18d8772b2399",
        companyName: "32fbbd3b-43c0-498a-8895-3126420bb2ca",
        teamSize: "47bd131b-7c57-44c2-aadb-38408c41d631",
        industryType: "8eb2ab87-177c-4a39-b157-9115fbb9a396"
    }
}

export const ROLE_LIST = [
    {
        id: 1,
        label: "CEO/C-Suite",
        optionId: 7211
    },
    {
        id: 2,
        label: "Customer Success",
        optionId: 7212
    },
    {
        id: 3,
        label: "Sales",
        optionId: 7213
    },
    {
        id: 4,
        label: "Marketing",
        optionId: 7214
    },
    {
        id: 5,
        label: "Monitoring & Evaluation",
        optionId: 7215
    },
    {
        id: 6,
        label: "Data Analyst",
        optionId: 7216
    },
    {
        id: 7,
        label: "Other",
        optionId: 7217
    },
]

export const COMPANY_SIZE_LIST = [
    {
        id: 1,
        label: "0-9",
        optionId: 7218
    },
    {
        id: 2,
        label: "10-49",
        optionId: 7219
    },
    {
        id: 3,
        label: "50-249",
        optionId: 7220
    },
    {
        id: 4,
        label: "250+",
        optionId: 7221
    },
    
]

export const INDUSTRY_CATEGORY = [
    {
        id: 1,
        label: "Agriculture",
        optionId: 7222
    },
    {
        id: 2,
        label: "Business",
        optionId: 7223
    },
    {
        id: 3,
        label: "Communications",
        optionId: 7224
    },
    {
        id: 4,
        label: "Construction",
        optionId: 7225
    },
    {
        id: 5,
        label: "Education",
        optionId: 7226
    },
    {
        id: 6,
        label: "Energy",
        optionId: 7227
    },
    {
        id: 7,
        label: "Entertainment",
        optionId: 7228
    },
    {
        id: 8,
        label: "Fashion",
        optionId: 7229
    },
    {
        id: 9,
        label: "Fishing",
        optionId: 7230
    },
    {
        id: 10,
        label: "Finance",
        optionId: 7231
    },
    {
        id: 11,
        label: "Health care",
        optionId: 7232
    },
    {
        id: 12,
        label: "Hospitality",
        optionId: 7233
    },
    {
        id: 3,
        label:"Insurance",
        optionId: 7234
    },
    {
        id: 14,
        label: "Investment",
        optionId: 7235
    },
    {
        id: 15,
        label: "Media",
        optionId: 7236
    },
    {
        id: 16,
        label: "Mining",
        optionId: 7237
    },
    {
        id: 17,
        label: "Manufacturing",
        optionId: 7238
    },
    {
        id: 18,
        label: "Recreation and sports entertainment",
        optionId: 7239
    },
    {
        id: 19,
        label: "Retail",
        optionId: 7240
    },
    {
        id: 20,
        label: "Telecommunications",
        optionId: 7241
    },
    {
        id: 21,
        label: "Transportation",
        optionId: 7242
    },
    {
        id: 22,
        label: "Technology",
        optionId: 7243
    },
    {
        id: 23,
        label:"Other",
        optionId: 7244
    },
    
]

export const COMPANY_TYPE_CATEGORY = [
    {
        id: 1,
        label: "Start up",
    },
    {
        id: 2,
        label: "SME",
    },
    {
        id: 3,
        label: "Corporate",
    },
    {
        id: 4,
        label: "Hub",
    },
    {
        id: 5,
        label: "NGO",
    },
    {
        id: 6,
        label: "School",
    },
    {
        id: 7,
        label: "Other",
    },
    
]

export const formDataInitState = {
    name: "",
    email:"",
    role: "",
    companyName: "",
    teamSize: "",
    industryType:""
}

export const formStatusInitState = {
    name: { error: false, helperText: null},
    email: { error: false, helperText: null},
    role: { error: false, helperText: null},
    companyName: { error: false, helperText: null},
    teamSize: { error: false, helperText: null},
    industryType: { error: false, helperText: null},        
}