// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fonts/NeueHaasDisplayBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/fonts/NeueHaasDisplayMediu.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  font-family: 'Ibarra Real Nova', serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto', sans-serif;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: medium;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sCAAsC;EACtC,kCAAkC;EAClC,iCAAiC;AACnC;;AAEA;EACE,4CAA4C;EAC5C,+DAAoE;EACpE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,4CAA4C;EAC5C,+DAAqE;EACrE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".App {\n  text-align: center;\n  font-family: 'Ibarra Real Nova', serif;\n  font-family: 'Poppins', sans-serif;\n  font-family: 'Roboto', sans-serif;\n}\n\n@font-face {\n  font-family: 'Neue Haas Grotesk Display Pro';\n  src: url('/public/fonts/NeueHaasDisplayBold.ttf') format('truetype');\n  font-weight: bold;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Neue Haas Grotesk Display Pro';\n  src: url('/public/fonts/NeueHaasDisplayMediu.ttf') format('truetype');\n  font-weight: medium;\n  font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
