import { Link, Stack, styled, Typography } from "@mui/material";

export const FooterContainer = styled(Stack)(({ theme }) => ({
    backgroundColor: "#919EAB0A"
}));

export const HorizontalStack = styled(Stack)(({ theme }) => ({
    height: "11.25rem",
    width: "80%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [ theme.breakpoints.down('md')]:{
        marginTop: "2rem",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        gap:"1.5rem"
    }
}));

export const VerticalStack = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    gap: "2rem"
}));

export const LegalStack = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "start",
    gap: "2rem"
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: "0.875rem",
    fontWeight: 400,
    [ theme.breakpoints.down('md')]:{
        fontSize: "0.75rem"
    }
}));


export const InfoStack = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    gap: "1rem"
}));


export const StyledLink = styled(Link)(({ theme }) => ({
    color: "#212B36",
    fontWeight: 600,
    fontSize: "0.875rem",
    textDecoration: "none",
    ":hover":{
        textDecoration: "underline"
    },
    [ theme.breakpoints.down("md")]:{
        fontSize: "0.75rem"
    }
}));