import { Typography, styled, keyframes } from "@mui/material";

export const StyledTypography = styled(Typography)(({ theme }) => ({
    fontFamily: "Neue Haas Grotesk Display Pro",
    fontSize: "1.75rem",
    fontWeight: 600,
    color: "#212B36",
    [ theme.breakpoints.down('md')]: {
        fontSize: "1rem"
    }
}))

export const ResponsiveVideo = styled('video')(({ theme }) => ({
    width: "958px",
    height: "auto",
    borderRadius: "1rem",
    [theme.breakpoints.down("lg")]:{
        width: "840px",
    },
    [theme.breakpoints.down("md")]:{
        width: "590px",
    },
    [theme.breakpoints.down("sm")]:{
        borderRadius: "0.5rem",
        width: "258px",
    }
}));

export const grow = keyframes`
    0% {
        opacity: 0;
        /* transform: scale(1.2); */
        width: 75px;
        height: 75px;
    }
    50% {
        opacity: 0.4;
        /* transform: scale(1.5); */
        width: 80px;
        height: 80px;
    }
    100%{
        opacity: 0.16;
        /* transform: scale(1.8); */
        width: 85px;
        height: 85px;
    }
`

export const VideoContainer = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "958px",
    height: "538px",
    margin: "1.5rem auto",
    borderRadius: "1rem",
    boxShadow: "10px 10px 10px #7f7f7f30",

    [theme.breakpoints.down("lg")]:{
        width: "258px",
        height: "auto",
    },

    [theme.breakpoints.between("md", "lg")]:{
        width: "258px",
        height: "auto",
    },
    [theme.breakpoints.down('sm')]:{
        borderRadius: "0.5rem",
        width: "258px",
        height: "auto"
    }
}));

export const PlayButton = styled('div')(({ theme }) => ({
    position: "absolute",
    width: "70px",
    height: "70px",
    cursor: "pointer",
    zIndex: 3,
}));

export const AnimCircle = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: "70px",
    height: "70px",
    transform: "translate(-5%, -100%)",
    borderRadius: "50%",
    zIndex: -1,
    backgroundColor: "#919EAB",
    animation: `${grow} 2s ease-in infinite`
}));