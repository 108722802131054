import { workbookData } from "./FormModal.constants";

export const validateEmail = (email) => {
    // Simple regex for email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};


export const updateFormData = async (formData)=> {
    const endPoint = `https://api-pre-prod-cluster.skizaa.com/api/v1/p/workbook/${workbookData?.workbookId}/rowData?isDraft=false`;
    const payload = [
        { columnUuid: workbookData?.columns?.name, stringValue: formData?.name },
        { columnUuid: workbookData?.columns?.email, emailValue: formData?.email },
        { columnUuid: workbookData?.columns?.role, columnOptionList: [formData?.role?.optionId] },
        { columnUuid: workbookData?.columns?.companyName, stringValue: formData?.companyName },
        { columnUuid: workbookData?.columns?.teamSize, columnOptionList: [formData?.teamSize.optionId] },
        { columnUuid: workbookData?.columns?.industryType, columnOptionList: [formData?.industryType.optionId] },
    ]

    try{
        const response = await fetch(endPoint, {
            method: "POST",
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(payload)
        })

        await response?.json();
        return response
    }
    catch(err){
        console.log("Error in posting response")
    }
}