import React from 'react'
import MainHeader from '../Components/MainHeader'
import { Box, Stack, styled } from '@mui/material'
import HeroSection from '../Components/HeroSection/HeroSection'
import DemoVideoSection from '../Components/DemoVieoSection/DemoVideoSection'
import SponserSection from '../Components/SponserSection'
import MainFooter from '../Components/MainFooter/MainFooter'
import PricingSection from '../Components/PricingSection/PricingSection'

const LandingPage = ()=> {
  return(
    <Container>
      <LandingPageArea>
        <MainHeader/>
        <HeroSection/>
        <DemoVideoSection/>
        <SponserSection/>
        <PricingSection/>
      </LandingPageArea>
      <MainFooter/>
    </Container>
  )
}

const Container =  styled(Box)(({ theme }) => ({
  width: "100%",
}))

const LandingPageArea = styled(Stack)(({ theme }) => ({
  width: "80%",
  margin: "0 auto",

  [theme.breakpoints.down('sm')]:{
    width: "90%"
  }
}))

export default LandingPage

// import Navbar from '../Pages/Navbar'
// import Home from '../Pages/Home'
// import Strip from '../Pages/Strip'
// import SchoolMonitoring from '../Pages/SchoolMonitoring'
// import Dashboard from '../Pages/Dashboard'
// import MobileDetails from '../Pages/MobileDetails'
// import VoiceCount from '../Pages/VoiceCount'
// import ContactSale from '../Pages/ContactSale'
// import BuildFor from '../Pages/BuildFor'
// import Footer from '../Pages/Footer'

// return (
//   <>
//       <Navbar/>
//       <Home />
//       <Strip />
//       <SchoolMonitoring />
//       <Dashboard />
//       <MobileDetails />
//       <VoiceCount />
//       <ContactSale />
//       <BuildFor />
//       <Footer />
//   </>
// )