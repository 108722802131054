import { styled, Typography } from "@mui/material";

export const StyledTypography =  styled(Typography)(({ theme }) => ({
    fontFamily: "Neue Haas Grotesk Display Pro",
    fontSize: "1.75rem",
    fontWeight: 600,
    [ theme.breakpoints.down('md')]:{
        fontSize: "1rem",
        width: "80%"
    }
}))