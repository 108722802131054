import { createTheme } from "@mui/material";

const FormTheme = createTheme({
    components:{
        MuiButton:{
          styleOverrides:{
            contained:{
              minWidth: "7.5rem",
              height: "3rem",
              borderRadius: "0.5rem",
              textTransform: "none",
              backgroundColor: "#5161FF",
              "&:hover":{
                backgroundColor: "#5161FF",
              }
            },
            outlined:{
              height: "3rem",
              textTransform: "none",
              borderRadius: "0.5rem",
            },
            text:{
              color: "#5161FF",
              textTransform: "none",
              fontSize: "0.9375rem",
              fontWeight: "500",
            }
          }
        },
        MuiTypography:{
          styleOverrides:{
            h5:{
              color: "#212B36"
            },
            h6:{
              color: "#212B36"
            },
            caption:{
              color: "#637381"
            },
            overline:{
              textTransform: "none",
              color: "#637381"
            },
            body2:{
              color: "#637381"
            },
          },
          variants:[
            {
              props: { variant: "pwdLink" },
              style:{
                color: "#5161FF",
                fontSize: "0.875rem",
                fontWeight: "500",
                cursor: "pointer",
                "&:hover": {
                  color: "#6171FF"
                }
              }
            }
          ]
        },
        MuiTextField:{
          styleOverrides:{
            root:{
              "& .MuiInputBase-root.Mui-error": {
                border: "2px solid #FF5630",
                "& fieldset":{
                  border: "none"
                }
              },
            }
          }
        },
        MuiLink:{
          variants:[
            {
              props: { variant: "pwdLink" },
              style:{
                color: "#5161FF",
                fontSize: "0.875rem",
                fontWeight: "500",
                cursor: "pointer",
                "&:hover": {
                  color: "#6171FF"
                }
              },
            },
            {
              props: { variant: "urlLink" },
              style:{
                color: "#5161FF",
                fontSize: "0.875rem",
                fontWeight: "700",
                cursor: "pointer",
                "&:hover": {
                  color: "#6171FF"
                }
              }
            }
          ]
        },
        MuiOutlinedInput:{
          styleOverrides:{
            root:{
              borderRadius: "0.5rem",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline":{
                border: "2px solid #46A833",
              },
            }
          },  
        },
      }
})

export default FormTheme