import React, { useState, useRef } from "react";
import { Stack } from "@mui/material";
import { StyledTypography } from "./DemoVideoSection.styles";
import { DemoVideoURL } from "./DemoVideoSection.constants";
import { VideoContainer, ResponsiveVideo, PlayButton, AnimCircle } from "./DemoVideoSection.styles";

const DemoVideoSection = ()=> {

    const [ isPlaying, setIsPlaying ] = useState(false)
    const vidRef = useRef(null)

    const handlePlayPause = () => {
        if (isPlaying) {
            vidRef.current.pause();
        } else {
            vidRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return(
        <Stack justifyContent={"center"} alignItems={"center"}>
            <StyledTypography>
                No Tech Skills Needed—You're Already a Data Analyst!
            </StyledTypography>
            <VideoContainer>
                <ResponsiveVideo ref={vidRef} width={"958"} controls={isPlaying} poster="/img/cover/cover_img.png">
                    <source src={DemoVideoURL} type="video/mp4" />
                </ResponsiveVideo>
                <PlayButton 
                    onClick={handlePlayPause}
                >
                    {!isPlaying && <div>
                        <img src={"/logo/misc/play.png"} alt="play" />
                        <AnimCircle className="anim-circle"></AnimCircle>
                    </div>}
                </PlayButton>
            </VideoContainer>
            
        </Stack>
    )
}

  

export default DemoVideoSection