import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import styled from "@emotion/styled";
import { Link } from 'react-router-dom';

const Container = styled(Grid)({   
  margin: "10px 100px 25px 100px",
  "@media (max-width: 960px)": {
    margin: "5px 5px 5px 5px",
  },
});

const InnerBox = styled(Box)({
//   background: "#EDEFFF",
  padding: "48px 86px",
  textAlign:'left',
  "@media (max-width: 960px)": {
    width: "98%",
    marginLeft: "1%",
    padding: "20px 15px 70px 15px",
  },
});

const SubtitleText = styled.h3`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 54px;
  color: #353535;
  @media (max-width: 960px) {
    font-size: 30px;
  }
`;
const Paragraph = styled(Typography)({
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "24px",
    fontFamily: "Arial",
    color: "#676767",
    flex: "none",
    margin: ".5rem 0 0.2rem 0",
    order: "1",
    flexGrow: "0",
  });
  const Icon = styled("img")({
    width: "100px",
    height: "100px",
    marginRight:'1rem'
})

const Item = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "27px",
  margin: "5px 0",
  "&:hover": {
    textDecoration: "underline",
    cursor: "pointer",
    transform: "scale(1.08)",
  },
});


export default function ConnectedHome() {
  return (
    <Container>
      <InnerBox>
         <div style={{display:'flex',justifyContent:'center',marginBottom:'1rem'}}>
            <Icon
                src={"/skizaa/Brandmark.png"}
            />
         </div>
          <SubtitleText style={{fontSize:"2rem"}}>Homepage </SubtitleText>
          <Paragraph>
            Accurately represents your app’s Identity? :- Our focus lies in gathering and analysing education data, particularly in remote regions, aiming to empower stakeholders such as NGOs, curriculum developers, and policy makers with actionable insights. Our platform enables users to effortlessly craft surveys tailored to their needs and seamlessly visualize the gathered data within Google Spreadsheets. This integration allows for comprehensive analysis and presentation of real-time education metrics, fostering informed decision-making and ultimately improving educational outcomes in underserved areas.
          </Paragraph>

          <SubtitleText>What will this app do with user data?</SubtitleText>
          <Paragraph>
            We provide seamless integration of survey from response with Google Spreadsheets. Our platform offers users the ability to grant access to their spreadsheets, enabling read and write permissions. This integration empowers users to effortlessly synchronize their spreadsheets with our platform, facilitating data export from our system to their Google Spreadsheets. To ensure security, we securely store essential identification elements such as workspace ID, spreadsheet ID, and access token refresh token within our highly secure AWS-powered database. These tokens are utilized to securely transmit data to users' designated spreadsheets, ensuring a genuine and secure integration via Google OAuth verification protocols.
          </Paragraph>

          <SubtitleText>How does this app enhance user functionality?</SubtitleText>
          <Paragraph>
            This app revolutionizes user functionality by enabling seamless survey creation for teachers, facilitating easy data collection pivotal for informed decision-making in education. The collected data serves as a catalyst for critical decision-making not only for individual users but also empowers NGOs to optimize resource allocation and enhance education quality. The integration with Google Sheets provides unparalleled flexibility, allowing effortless export of data for personalized management, analysis, and sharing among team members, fostering collaboration and maximizing the utility of the insights gleaned from the surveys.
          </Paragraph>

          <SubtitleText>Google OAuth2 Limited Use Disclosure</SubtitleText>
          <Paragraph>
            App's use of information received from Google APIs will adhere to the Google API Services User Data Policy, including the Limited Use requirements.
          </Paragraph>       
      </InnerBox>
      <Link
        to={
          "/home/privacy-policy"
        }
        target="_blank"
      >
        <Item>Privacy Policy</Item>
      </Link>
    </Container>
  );
}
