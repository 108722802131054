import React from "react";
import {Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";


const Icon = styled("img")({
    width: "125px",
    height: "125px"
})

const LinkIcon = styled("img")({
    width: "15px",
    height: "15px",
    margin: "0 5px 0 5px",
})

const Highlight = styled("span")({
    color: "rgb(51, 126, 169)",
    fontWeight: "600"
})

const BoxContainer = styled(Box)({
    textAlign: "left",
    maxWidth: "40%",
    marginTop: "8rem",
    marginLeft: "auto",
    marginRight: "auto",
  });

const Heading1 = styled(Typography)({
    fontSize: "38px",
    fontWeight: "600",
    lineHeight: "48px",
    margin: "2rem 0 4rem 0",
    fontFamily: "Poppins",
    color: "#353535",
  });

const Heading2 = styled(Typography)({
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "48px",
    fontStyle: "normal",
    fontFamily: "Poppins",
    marginTop: "32px",
    color: "#353535",
  });
const Heading3 = styled(Typography)({ 
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "48px",
    fontStyle: "italic",
    fontFamily: "Poppins",

    marginTop: "24px",
    color: "#353535",
  });

const Paragraph = styled(Typography)({
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "24px",
    fontFamily: "Arial",
    color: "#676767",
    flex: "none",
    margin: "1rem 0 0.2rem 0",
    order: "1",
    flexGrow: "0",
  });

const StyledLink = styled(Link)({
    margin: "2px",
    padding: "3px",
    // textDecoration: "none",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    textDecoration: "underline",

    "&:hover": {
        backgroundColor: "#efefef",
        transition: "0.3s",

      },
  });

const PrivacyPage = ()=>{
    return(
        <BoxContainer>
            <Icon
                src={"/skizaa/Brandmark.png"}
            />
            <Heading1>PRIVACY POLICY SKIZAA EDUCATION LIMITED</Heading1>
            <Paragraph>
                Skizaa Education Limited (the "<Highlight>Company</Highlight>") is committed to maintaining robust privacy protections 
                for its users. Our Privacy Policy (“<Highlight>Privacy Policy</Highlight>”) is designed to help you understand how we 
                collect, use and safeguard the information you provide to us and to assist you in making informed 
                decisions when using our Service.
            </Paragraph>
            <Paragraph>
                For purposes of this Agreement, “<Highlight>Site</Highlight>” refers to the Company's website, which can be accessed at 
                https://skizaa.com 
            </Paragraph>
            <Paragraph>
                “<Highlight>Service</Highlight>” refers to the Company's services accessed via the Site, in which users can find out more 
                about our offering and get started using the Skizaa application.
                <br/>
                The terms “<Highlight>we</Highlight>,” “<Highlight>us</Highlight>,” and “<Highlight> our</Highlight> ” refer to the Company.
            </Paragraph>
            <Paragraph>
                “<Highlight>You</Highlight>” refers to you, as a user of our Site or our Service.
            </Paragraph>
            <Paragraph>
                By accessing our Site or our Service, you accept our Privacy Policy and Terms of Use 
                (found here:  
                {<StyledLink to="//www.notion.so/TERMS-OF-USE-SKIZAA-EDUCATION-LIMITED-9f43f1a5c6ef42eea648a8694408feea?pvs=21)" target="_blank"><LinkIcon src={"/skizaa/Brandmark.png"}/>TERMS OF USE SKIZAA EDUCATION LIMITED</StyledLink>}
                ), and you consent to our collection, storage, use and disclosure of your Personal Information as described in 
                this Privacy Policy.
            </Paragraph>
            <br/>
            <Heading2>🧳 1. INFORMATION WE COLLECT</Heading2>
            <Paragraph>
                We collect “<Highlight>Non-Personal Information</Highlight>” and “<Highlight>Personal Information</Highlight>” Non-Personal 
                Information includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we 
                may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences that are generated 
                based on the data you submit and number of clicks. Personal Information which may include, but not limited to, your 
                full name, company, role, email, which you may submit to us through the registration process at the Site.
            </Paragraph>
            <Heading3>1. Information collected via Technology</Heading3>
            <Paragraph>      
                To activate the Service you do not need to submit any Personal Information other than your email address, name and 
                company. To use the Service thereafter, you will need to submit further Personal Information, which may include: 
                your role, your phone number. However, in an effort to improve the quality of the Service, we track information 
                provided to us by your browser or by our software application when you view or use the Service, such as the website 
                you came from (known as the “<Highlight>referring URL</Highlight>”), the type of browser you use, the device from which you connected 
                to the Service, the time and date of access, and other information that does not personally identify you. We track 
                this information using cookies, or small text files which include an anonymous unique identifier. Cookies are sent 
                to a user's browser from our servers and are stored on the user's computer hard drive. Sending a cookie to a user's 
                browser enables us to collect Non-Personal information about that user and keep a record of the user's preferences 
                when utilizing our services, both on an individual and aggregate basis. For example, the Company may use cookies to 
                collect the following information:
                <ul>
                    <li>IP address</li>
                    <li>Browser type</li>
                    <li>Browser Version</li>
                    <li>Pages of our site that you visit</li>
                    <li>The time and data of your visit</li>
                    <li>Time spent on pages of our site</li>
                    <li>Unique Identifiers</li>
                    <li>Other Diagnostic Data</li>
                </ul>
            </Paragraph>
            <Paragraph>
                The Company may use both persistent and session cookies; persistent cookies remain on your computer after you close your 
                session and until you delete them, while session cookies expire when you close your browser.
            </Paragraph>
            <Heading3>2. Children's Privacy</Heading3>
            <Paragraph>
            The Site and the Service are not directed to anyone under the age of 13. The Site does not knowingly collect or solicit 
            information from anyone under the age of 13, or allow anyone under the age of 13 to sign up for the Service. In the event 
            that we learn that we have gathered personal information from anyone under the age of 13 without the consent of a parent 
            or guardian, we will delete that information as soon as possible. If you believe we have collected such information, please 
            contact us at <Highlight>contact@skizaa.com</Highlight>.
            </Paragraph>
            <Heading2>💬 2. HOW WE USE AND SHARE INFORMATION</Heading2>
            <Heading3>Personal Information:</Heading3>
            <Paragraph>
                Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes 
                your Personal Information with third parties without your consent. We do share Personal Information with vendors who are 
                performing services for the Company, such as the servers for our email communications who are provided access to user's 
                email address for purposes of sending emails from us. Those vendors use your Personal Information only at our direction 
                and in accordance with our Privacy Policy.
            </Paragraph>
            <Paragraph>
                In general, the Personal Information you provide to us is used to help us communicate with you. For example, we use Personal 
                Information to contact users in response to questions, solicit feedback from users, provide technical support, and inform 
                users about promotional offers.
            </Paragraph>
            <Paragraph>
                We may share Personal Information with outside parties if we have a good-faith belief that access, use, preservation or 
                disclosure of the information is reasonably necessary to meet any applicable legal process or enforceable governmental 
                request; to enforce applicable Terms of Service, including investigation of potential violations; address fraud, security 
                or technical concerns; or to protect against harm to the rights, property, or safety of our users or the public as required 
                or permitted by law.
            </Paragraph>

                <Heading3>Non-Personal Information</Heading3>
            <Paragraph>
                In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We also 
                aggregate Non-Personal Information in order to track trends and analyze use patterns on the Site. This Privacy Policy 
                does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose 
                such Non-Personal Information to our partners, advertisers and other third parties at our discretion.
            </Paragraph>
            <Paragraph>
                In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a 
                portion of our assets, your Personal Information may be among the assets transferred. You acknowledge and consent that 
                such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our assets may continue to 
                process your Personal Information as set forth in this Privacy Policy. If our information practices change at any time 
                in the future, we will post the policy changes to the Site so that you may opt out of the new information practices. 
                We suggest that you check the Site periodically if you are concerned about how your information is used.
            </Paragraph>
            <Heading2>🛡️ 3. HOW WE PROTECT INFORMATION</Heading2>
            <Paragraph>
                We implement security measures designed to protect your information from unauthorized access. Your account is protected 
                by your account password and we urge you to take steps to keep your personal information safe by not disclosing your 
                password and by logging out of your account after each use. We further protect your information from potential security 
                breaches by implementing certain technological security measures including encryption, firewalls and secure socket layer 
                technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or 
                destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand 
                and agree to assume these risks.
            </Paragraph>

            <Heading3>1. YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION</Heading3>
            <Paragraph>
                You have the right at any time to prevent us from contacting you for marketing purposes. When we send a promotional 
                communication to a user, the user can opt out of further promotional communications by following the unsubscribe instructions 
                provided in each promotional e-mail. You can also indicate that you do not wish to receive marketing communications from 
                us in the “<Highlight>Settings</Highlight>” section of the Site. Please note that notwithstanding the promotional preferences you indicate by 
                either unsubscribing or opting out in the Settings of the Site, we may continue to send you administrative emails including, 
                for example, periodic updates to our Privacy Policy.
            </Paragraph>

            <Heading3>2. LINKS TO OTHER WEBSITES</Heading3>
            <Paragraph>
                As part of the Service, we may provide links to or compatibility with other websites or applications. However, we are not 
                responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy 
                Policy applies solely to information collected by us through the Site and the Service. Therefore, this Privacy Policy does 
                not apply to your use of a third party website accessed by selecting a link on our Site or via our Service. To the extent 
                that you access or use the Service through or on another website or application, then the privacy policy of that other 
                website or application will apply to your access or use of that site or application. We encourage our users to read the 
                privacy statements of other websites before proceeding to use them.
            </Paragraph>

            <Heading3>3. CHANGES TO OUR PRIVACY POLICY</Heading3>
            <Paragraph>
                The Company reserves the right to change this policy and our Terms of Service at any time.  We will notify you of 
                significant changes to our Privacy Policy by sending a notice to the primary email address specified in your account 
                or by placing a prominent notice on our site. Significant changes will go into effect 30 days following such notification. 
                Non-material changes or clarifications will take effect immediately. You should periodically check the Site and this 
                privacy page for updates.
            </Paragraph>

            <Heading2>☎️ 4. CONTACT US</Heading2>
            <Paragraph>
                If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us by sending 
                an email to <Highlight>contact@skizaa.com</Highlight>
            </Paragraph>
            <Paragraph>
                Last Updated: This Privacy Policy was last updated on <span style={{fontWeight: "600"}}>10th June 2023</span>.
            </Paragraph>
            <br/><br/><br/>
        </BoxContainer>
    );
}
export default PrivacyPage