import React from "react";

import SkizaaIcon from "../../Templates/SkizaaIcon";
import { HeaderArea } from "./MainHeader.styles";

const MainHeader = ()=> {
    return(
        <HeaderArea>
            <SkizaaIcon width={102} height={24} />
        </HeaderArea>
    )
}

export default MainHeader