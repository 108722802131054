import Box from "@mui/material/Box";
import styled from "styled-components";

export const HeaderArea = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "5rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
}))