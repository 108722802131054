import { Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { HeroTextDesc, HeroTextTitle } from '../HeroSection/HeroSection.styles';

const PricingSection = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;

    if (containerRef.current) {
      containerRef.current.appendChild(script);
    }
  }, []);

  return (
        <div ref={containerRef} style={{display:"flex", flexDirection:"column", gap:"8px", marginTop:"4rem", marginBottom:"4rem"}}>
          <HeroTextTitle>Pricing</HeroTextTitle>
          <HeroTextDesc>Connect Multiple data sources and generate insights in minutes</HeroTextDesc>
          <stripe-pricing-table pricing-table-id="prctbl_1Q2ZJRBb6rJgvmRlYKis2RNJ"
             publishable-key="pk_live_51KjMRRBb6rJgvmRlMW86nf0fccEtwdKL0fs41qhdwrBJZKJb6whLhMjbdNMIvVe6XZBOCAdzjkafy7nQyj93sRCP00aoE7ynX9">
          </stripe-pricing-table>
        </div>
  );
};

export default PricingSection;

