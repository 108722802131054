import React from "react";
import { Stack, styled } from "@mui/material";
import { SponserLogoStack } from "./SponserSection.constants";
import { StyledTypography } from "./SponserSection.styles";

const SponserSection = ()=> {
    return(
        <Container>
            <StyledTypography>
                Trusted Worldwide, Analyzing 15M+ Datapoints Every Day
            </StyledTypography>
            <SponserLogoStack/>
        </Container>
    )
}  

const Container =  styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2.5rem",
    [ theme.breakpoints.down('md')]:{
        fontSize: "1rem"
    }
}))

export default SponserSection