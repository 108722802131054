import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './Routes/LandingPage';
import PrivacyPage from './Pages/PrivacyPage';
import { createTheme,ThemeProvider } from '@mui/material/styles';
import ConnectedHome from './Pages/connectPage/Home';
import ConnectPrivacy_policy from './Pages/connectPage/Privacy_policy';
import TermsOfUse from './Pages/connectPage/TermsOfUse';

function App() {
  const theme = createTheme();
  return (
    <div className="App">
       <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/privacy-policy" element={<PrivacyPage/>}/>
            <Route path="/home" element={<ConnectedHome/>}/>
            <Route path="/home/privacy-policy" element={<ConnectPrivacy_policy />} />
            <Route path="/terms-of-use" element={<TermsOfUse/>} />
          </Routes>
       </ThemeProvider>
    </div>
  );
}

export default App;
