import React from "react";
import { HeroSectionArea, HeroTextDesc, HeroTextTitle } from "./HeroSection.styles";
import { Box } from "@mui/material";
import { HERO_DESC, HERO_HEADER } from "./HeroSection.constants";
import LaunchInfo from "../../Templates/LaunchInfo/LaunchInfo";

const HeroSection = ()=> {
    return (
        <HeroSectionArea>
            <HeroTextTitle> { HERO_HEADER } </HeroTextTitle>
            <Box sx={{ marginTop: "0.2rem" }}/>
            <HeroTextDesc>{ HERO_DESC }</HeroTextDesc>
            <LaunchInfo/>
        </HeroSectionArea>
    )
}
export default HeroSection